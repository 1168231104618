import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import empHome from '@/views/empHome.vue'
Vue.use(VueRouter)
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
    {
        path:'/emp',
        redirect:'/emp_login'
    },
    {
        path: '/emp/index',
        component: empHome,
        children:[
            {
                name:'生产管理首页',
                path:'index',
                component: resolve => require(["@/views/plant/Home.vue"], resolve),
                meta: {
                    title: "生产管理首页",
                    requiresAuth: true,
                    is_emp:'1'
                },
            }
        ]
    },
    //外出管理
    {
        path: '/home/outside_apply',
        component: empHome,
        children:[
          {
            name:'我的外出',
            path:'my_outside_list',
            component: resolve => require(["@/views/outsideApply/myOutsideList.vue"], resolve),
            meta:{
              title:'我的外出',
              is_emp:'1'
            }
          },
          {
            name:'审批列表',
            path:'check_list',
            component: resolve => require(["@/views/outsideApply/checkList.vue"], resolve),
            meta:{
              title:'审批列表',
               is_emp:'1'
            }
          },
          {
            name:'全部列表',
            path:'all_list',
            component: resolve => require(["@/views/outsideApply/allList.vue"], resolve),
            meta:{
              title:'全部列表',
               is_emp:'1'
            }
          },
        ]
       },
    {
        path: '/home/bao_gong',
        component: empHome,
        children:[
             //零活初审
             {
                name:'零活初审列表',
                path:'ling_huo_flist',
                component: resolve => require(["@/views/empOdd/lhFList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            //零活复审
            {
                name:'零活复审列表',
                path:'ling_huo_slist',
                component: resolve => require(["@/views/empOdd/jobRecheckList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            //异常耗时初审
            {
                name:'异常耗时初审',
                path:'exception_hf_list',
                component: resolve => require(["@/views/empOdd/exceptionHourList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            //异常耗时复审
            {
                name:'异常耗时复审',
                path:'exception_hs_list',
                component: resolve => require(["@/views/empOdd/exceptionHsList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            //报工效率
            {
                name:'报工效率',
                path:'work_list',
                component: resolve => require(["@/views/empOdd/workList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            
        ], 
    },
    //申诉
    {
        path: '/home/appeal',
        component: empHome,
        children:[
            //现场申诉
            {
                name:'现场申诉',
                path:'xc_appeal_list',
                component: resolve => require(["@/views/empOdd/siteWorkList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'现场申诉审核',
                path:'xc_manage_list',
                component: resolve => require(["@/views/empOdd/xcManageList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'工时申诉',
                path:'time_appeal_list',
                component: resolve => require(["@/views/empOdd/timeWorkList.vue"], resolve),
                meta:{
                    is_emp:'1'
                },
            },
            {
                name:'工时申诉审核',
                path:'time_manage_list',
                component: resolve => require(["@/views/empOdd/timeManageList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'工时申诉-添加工时',
                path:'add_work_time',
                component: resolve => require(["@/views/empOdd/addWorkList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            
        ]
    },
    //设备管理
    {
        path: '/home/mach',
        component: empHome,
        children:[
            //维修申请
            {
                name:'维修申请列表',
                path:'repair_apply_list',
                component: resolve => require(["@/views/empOdd/repairApplyList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'维修申请详情',
                path:'repair_apply_detail',
                component: resolve => require(["@/views/empOdd/repairApplyDetail.vue"], resolve),
                meta:{
                    is_emp:'1',
                    title:'维修申请详情'
                  }
            },
            {
                name:'维修派工列表',
                path:'repair_line_list',
                component: resolve => require(["@/views/empOdd/repairLineList.vue"], resolve),
                meta:{
                    is_emp:'1',
                    title:'维修派工列表'
                }
            },
        ]
    },
    //请假管理
    {
        path: '/home/leave_apply',
        component: empHome,
        children:[
            //零活复审
            {
                name:'我的申请',
                path:'my_apply_list',
                component: resolve => require(["@/views/empOdd/dataList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            //
            {
                name:'审批列表',
                path:'manage_list',
                component: resolve => require(["@/views/empOdd/manageList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'全部申请',
                path:'total_list',
                component: resolve => require(["@/views/empOdd/totalList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
        ], 
    },
    //派工管理
    {
        path: '/home/gx_pai_gong',
        component: empHome,
        children:[
            {
                name:'派工列表',
                path:'work_left_list',
                component: resolve => require(["@/views/empOdd/workLeftList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            }
        ],
    },
    //出勤推送
    {
        path: '/home/user',
        component: empHome,
        children:[
            {
                name:'加班夜班列表',
                path:'overtime_list',
                component: resolve => require(["@/views/empOdd/overtimeList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            },
            {
                name:'出勤推送',
                path:'chu_qin_list',
                component: resolve => require(["@/views/empOdd/chuqinList.vue"], resolve),
                meta:{
                    is_emp:'1'
                }
            }
        ],
    },
    //重定向到首页
    {
        path: '/',
        redirect: '/home/index/index'
    },
    {
        path: '/home/index',
        component: Home,
        children:[
            {
                name:'首页',
                path:'index',
                component: resolve => require(["@/views/Home/index.vue"], resolve),
                meta: {
                    title: "首页",
                    requiresAuth: true
                },
            }
        ]
        
    },
    {
        path: '/home/system',
        component: Home,
        children:[
             {
                name:'apache错误日志',
                path:'apache_log',
                component: resolve => require(["@/views/system/apacheLog.vue"], resolve),
               
            },
            {
                name:'运行日志',
                path:'runtime_list',
                component: resolve => require(["@/views/system/runtimeList.vue"], resolve),
               
            },
            {
                name:'运行日志',
                path:'runtime_first',
                component: resolve => require(["@/views/system/runtimeFirst.vue"], resolve),
                meta:{
                  title:'运行日志'
                }
            },
            {
              name:'运行日志',
              path:'runtime_second',
              component: resolve => require(["@/views/system/runtimeSecond.vue"], resolve),
              meta:{
                title:'运行日志'
              }
          },
          {
            name:'运行日志',
            path:'runtime_third',
            component: resolve => require(["@/views/system/runtimeThird.vue"], resolve),
            meta:{
              title:'运行日志'
            }
            },
        ]
    },
    {
        path: '/home/user',
        component: Home,
        children:[
            {
                name:'修改密码',
                path:'set_password',
                component: resolve => require(["@/views/login/setPass.vue"], resolve),
            },
            {
                name:'帮助文档',
                path:'help_doc_list',
                component: resolve => require(["@/views/login/helpDocList.vue"], resolve),
            },
        ]
    },
    //权限管理
    {
        path: '/home/rbac',
        component: Home,
        children:[
            {
                name:'权限列表',
                path:'auth_list',
                component: resolve => require(["@/views/rbac/authList.vue"], resolve),
            },
            {
                name:'角色列表',
                path:'role_list',
                component: resolve => require(["@/views/rbac/roleList.vue"], resolve),
            },
            {
                name:'绑定用户',
                path:'bind_user',
                component: resolve => require(["@/views/rbac/bindUser.vue"], resolve),
            },
            {
                name:'绑定权限',
                path:'bind_auth',
                component: resolve => require(["@/views/rbac/bindAuth.vue"], resolve),
            },
            
        ],
        
    },
    //日常相关
    {
        path: '/home/day_work',
        component: Home,
        children:[
            {
                name:'考勤列表',
                path:'show_kq_list',
                component: resolve => require(["@/views/day_work/checkingList.vue"], resolve),
            },
            {
                name:'工作日志',
                path:'work_logs',
                component: resolve => require(["@/views/day_work/workLogs.vue"], resolve),
            },
        ],
    },
    //车辆信息管理
    {
        path: '/home/car_admin',
        component: Home,
        children:[
            {
                name:'行驶记录列表',
                path:'data_user_list',
                component: resolve => require(["@/views/car_admin/dataSumUser.vue"], resolve),
            },
            {
                name:'车辆变更',
                path:'car_change_list',
                component: resolve => require(["@/views/car_admin/carChangeList.vue"], resolve),
            },
            //车辆列表
            {
                name:'车辆列表',
                path:'car_info_list',
                component: resolve => require(["@/views/car_admin/carInfoList.vue"], resolve),
            },
            {
                name:'车辆报表',
                path:'data_report',
                component: resolve => require(["@/views/car_admin/carReport.vue"], resolve),
            },
            {
                name:'车辆档案',
                path:'car_file',
                component: resolve => require(["@/views/car_admin/carFile.vue"], resolve),
            },
            {
                name:'车辆审批',
                path:'brow_appr_list',
                component: resolve => require(["@/views/car_admin/browAppr.vue"], resolve),
            },
            {
                name:'借车审批',
                path:'approve_car',
                component: resolve => require(["@/views/car_admin/approveCar.vue"], resolve),
            },
            {
                name:'乘车审批',
                path:'riding_replen',
                component: resolve => require(["@/views/car_admin/replenCar.vue"], resolve),
            },
            {
                name:'公车警报',
                path:'public_appr_list',
                component: resolve => require(["@/views/car_admin/publicList.vue"], resolve),
            },
            {
                name:'修正记录',
                path:'update_recode',
                component: resolve => require(["@/views/car_admin/reportCar.vue"], resolve),
            },
            {
                name:'车辆维护',
                path:'car_keep',
                component: resolve => require(["@/views/car_admin/carKeep.vue"], resolve),
            },
            {
                name:'车辆信息',
                path:'car_list',
                component: resolve => require(["@/views/car_admin/carMessage.vue"], resolve),
            },
            {
                name:'车辆列表',
                path:'info_car',
                component: resolve => require(["@/views/car_admin/carList.vue"], resolve),
            },
            {
                name:'车辆编辑',
                path:'info_car',
                component: resolve => require(["@/views/car_admin/carEdit.vue"], resolve),
            },
            {
                name:'导出预算',
                path:'export',
                component: resolve => require(["@/views/car_admin/carExport.vue"], resolve),
            },
            {
                name:'钥匙柜列表',
                path:'cabinet_list',
                component: resolve => require(["@/views/car_admin/cabinetList.vue"], resolve),
            },
            {
                name:'钥匙列表',
                path:'key_list',
                component: resolve => require(["@/views/car_admin/keyList.vue"], resolve),
            },
            {
                name:'管理员介入',
                path:'admin_inter',
                component: resolve => require(["@/views/car_admin/adminInter.vue"], resolve),
            },
            {
                name:'车辆管理员_司机列表',
                path:'driver_list',
                component: resolve => require(["@/views/car_admin/driverList.vue"], resolve),
            },
            {
                name:'车辆管理员_保险管理',
                path:'insure_list',
                component: resolve => require(["@/views/car_admin/insureList.vue"], resolve),
            },
            {
                name:'油卡管理',
                path:'oil_card',
                component: resolve => require(["@/views/car_admin/oilCard.vue"], resolve),
                children:[
                    {
                        name:'管理_油卡详情',
                        path:'oil_detail',
                        component: resolve => require(["@/views/car_admin/oilDetail.vue"], resolve),
                    },
                ]
            },
            {
                name:'油卡详情',
                path:'oil_detail',
                component: resolve => require(["@/views/car_admin/oilDetail.vue"], resolve),
            },
            {
                name:'维修保养',
                path:'repair',
                component: resolve => require(["@/views/car_admin/repairList.vue"], resolve),
            },
            {
                name:'事故统计',
                path:'accident',
                component: resolve => require(["@/views/car_admin/accidentList.vue"], resolve),
            },
            {
                name:'数据汇总',
                path:'data_sum_list',
                component: resolve => require(["@/views/car_admin/dataSum.vue"], resolve),
            },
            {
                name:'数据汇总-人员',
                path:'data_sum_user',
                component: resolve => require(["@/views/car_admin/dataSumUser.vue"], resolve),
            },
            {
                name:'查看提醒',
                path:'service_notice',
                component: resolve => require(["@/views/car_admin/serviceNotice.vue"], resolve),
            },
        ] 
    },
    //车辆管理
    {
        path: '/home/car',
        component: Home,
        children:[
            {
                name:'驾驶技术考核',
                path:'my_exam_list',
                component: resolve => require(["@/views/car/myExamList.vue"], resolve),
            },
            {
                name:'驾驶考核审批',
                path:'exam_list',
                component: resolve => require(["@/views/car/examList.vue"], resolve),
            },
            {
                name:'全部申请',
                path:'all_private_list',
                component: resolve => require(["@/views/car/allCarList.vue"], resolve),
            },
            {
                name:'公用申请-地图',
                path:'use_carMap',
                component: resolve => require(["@/views/car/useCarMap.vue"], resolve),
            },
            {
                name:'我的行驶记录',
                path:'my_public_used_list',
                component: resolve => require(["@/views/car/myCarUsedList.vue"], resolve),
            },
            {
                name:'行驶记录',
                path:'public_used_list',
                component: resolve => require(["@/views/car/publicUsedList.vue"], resolve),
            },
            {
                name:'公用行驶记录',
                path:'public_driving_list',
                component: resolve => require(["@/views/car/publicUsed.vue"], resolve),
            },
            {
                name:'私用行驶记录',
                path:'driving_recordList',
                component: resolve => require(["@/views/car/drivingRecordList.vue"], resolve),
            },
            {
                name:'车辆状态',
                path:'car_status_list',
                component: resolve => require(["@/views/car/carStatus.vue"], resolve),
            },
            {
                name:'审核列表',
                path:'car_audit',
                component: resolve => require(["@/views/car/auditCar.vue"], resolve),
            },
            {
                name:'公用申请',
                path:'public_list',
                component: resolve => require(["@/views/car/publicApply.vue"], resolve),
            },
            {
                name:'借车列表',
                path:'use_car',
                component: resolve => require(["@/views/car/useCar.vue"], resolve),
            },
            {
                name:'乘车列表',
                path:'riding_car',
                component: resolve => require(["@/views/car/ridingCar.vue"], resolve),
            },
            {
                name:'私用申请',
                path:'apply_private_list',
                component: resolve => require(["@/views/car/privateCar.vue"], resolve),
            },
            {
                name:'私用申请-行驶记录详情',
                path:'driving_record',
                component: resolve => require(["@/views/car/drivingRecord.vue"], resolve),
            },
            {
                name:'提前私用审批',
                path:'early_private_list',
                component: resolve => require(["@/views/car/auditPrivateCar.vue"], resolve),
            },
            {
                name:'钥匙柜',
                path:'key_car',
                component: resolve => require(["@/views/car/keyCar.vue"], resolve),
            },
            {
                name:'管理介入',
                path:'admin_car',
                component: resolve => require(["@/views/car/adminCar.vue"], resolve),
            },
            {
                name:'司机列表',
                path:'driver_car',
                component: resolve => require(["@/views/car/driverCar.vue"], resolve),
            },
            {
                name:'加油管理',
                path:'oil',
                component: resolve => require(["@/views/car/oil.vue"], resolve),
            },
            {
                name:'车辆管理',
                path:'car_manage',
                component: resolve => require(["@/views/car/carManage.vue"], resolve),
            },
            {
                name:'保险管理',
                path:'car_insure',
                component: resolve => require(["@/views/car/carInsure.vue"], resolve)
            },
            {
                name:'行驶证借用',
                path:'my_licence_borrow',
                component: resolve => require(["@/views/car/myLicence.vue"], resolve)
            },
            {
                name:'行驶证借用审批',
                path:'licence_borrow_list',
                component: resolve => require(["@/views/car/licenceList.vue"], resolve)
            },
        ],
    },
    //办公用品
    {
        path :'/home/office_product',
        component: Home,
        children:[
            {
                name:'分类列表',
                path:'category_list',
                component: resolve => require(["@/views/office/categoryList.vue"], resolve),
            },
            {
                name:'办公用品_物品列表',
                path:'product_list',
                component: resolve => require(["@/views/office/goodsList.vue"], resolve),
            },
            {
                name:'订单列表',
                path:'order_list',
                component: resolve => require(["@/views/office/orderList.vue"], resolve),
            },
            {
                name:'货道列表',
                path:'slot_list',
                component: resolve => require(["@/views/office/slotList.vue"], resolve),
            },
            {
                name:'订单管理',
                path:'order_manage',
                component: resolve => require(["@/views/office/orderManage.vue"], resolve),
            },
            {
                name:'库存列表',
                path:'stock_list',
                component: resolve => require(["@/views/office/stockList.vue"], resolve),
            },
            {
                name:'库存日志列表',
                path:'stocklog_list',
                component: resolve => require(["@/views/office/stocklogList.vue"], resolve),
            },
            {
                name:'物品领取',
                path:'in_stock_list',
                component: resolve => require(["@/views/office/goodsRece.vue"], resolve),
            },
            
        ],
    },
    //运输管理系统
    {
        path :'/home/trans_manage',
        component: Home,
        children:[
            {
                name:'回单列表',
                path:'receipt_list',
                component: resolve => require(["@/views/trans_manage/receiptList.vue"], resolve),
            },
            {
                name:'省市列表',
                path:'city_list',
                component: resolve => require(["@/views/trans_manage/city.vue"], resolve),
            },
            {
                name:'承运商列表',
                path:'carrier_list',
                component: resolve => require(["@/views/trans_manage/carrierList.vue"], resolve),
            },
            {
                name:'分公司列表',
                path:'company_list',
                component: resolve => require(["@/views/trans_manage/companyList.vue"], resolve),
            },
            {
                name:'分公司员工权限',
                path:'company_rabc',
                component: resolve => require(["@/views/trans_manage/rabcList.vue"], resolve),
            },
            {
                name:'基本运费',
                path:'jbyf_list',
                component: resolve => require(["@/views/trans_manage/basicPrice.vue"], resolve),
            },
            {
                name:'运单列表',
                path:'yf_order_list',
                component: resolve => require(["@/views/trans_manage/priceOrder.vue"], resolve),
            },
            {
                name:'运单列表-添加',
                path:'add_order_list',
                component: resolve => require(["@/views/trans_manage/addPriceOrder.vue"], resolve),
            },
            {
                name:'运单列表-确认',
                path:'affirm_order',
                component: resolve => require(["@/views/trans_manage/affirmOrder.vue"], resolve),
            },
            {
                name:'运单查询',
                path:'order_select',
                component: resolve => require(["@/views/trans_manage/orderSelect.vue"], resolve),
            },
            {
                name:'交货单号查询',
                path:'get_list_by_fbillno',
                component: resolve => require(["@/views/trans_manage/deliverySelect.vue"], resolve),
            },
            {
                name:'审核订单',
                path:'check_order',
                component: resolve => require(["@/views/trans_manage/checkOrder.vue"], resolve),
            },
            {
                name:'结算订单',
                path:'settle_account',
                component: resolve => require(["@/views/trans_manage/settleAccount.vue"], resolve),
            },
            {
                name:'数据分析',
                path:'order_analysis',
                component: resolve => require(["@/views/trans_manage/orderAnalysis.vue"], resolve),
            },
            {
                name:'仓库列表',
                path:'stock_list',
                component: resolve => require(["@/views/trans_manage/stockList.vue"], resolve),
            },
            {
                name:'仓库绑定权限',
                path:'stock_rabc',
                component: resolve => require(["@/views/trans_manage/stockRabc.vue"], resolve),
            },
            {
                name:'收货地址管理',
                path:'receipt_addr_list',
                component: resolve => require(["@/views/trans_manage/addressList.vue"], resolve),
            },
            {
                name:'快递定价',
                path:'express_price',
                component: resolve => require(["@/views/trans_manage/expressPriceList.vue"], resolve),
            },
        ]
    },
    //客户拜访
    {
        path :'/home/visit_plan',
        component: Home,
        children:[
            {
                name:'计划列表',
                path:'plan_list',
                component: resolve => require(["@/views/client/planList.vue"], resolve),
            },
            {
                name:'报告',
                path:'report',
                component: resolve => require(["@/views/client/reportList.vue"], resolve),
            },
            {
                name:'查看报告',
                path:'report_info',
                component: resolve => require(["@/views/client/reportInfo.vue"], resolve),
            },
            {
                name:'计划审核列表',
                path:'plan_audit_list',
                component: resolve => require(["@/views/client/auditPlan.vue"], resolve),
            },
            {
                name:'客户列表',
                path:'company_address_list',
                component: resolve => require(["@/views/client/clienList.vue"], resolve),
            },
            {
                name:'客户统计',
                path:'level_count',
                component: resolve => require(["@/views/client/levelCount.vue"], resolve),
            },
            {
                name:'批注列表',
                path:'plan_remark_list',
                component: resolve => require(["@/views/client/planRemarkList.vue"], resolve),
            },
        ]
    },
    //积分管理
    {
        path :'/home/integral',
        component: Home,
        children:[
            {
                name:'积分列表',
                path:'integral_list',
                component: resolve => require(["@/views/integral/integralList.vue"], resolve),
            },
            {
                name:'消费列表',
                path:'consume_list',
                component: resolve => require(["@/views/integral/consumeList.vue"], resolve),
            },
            {
                name:'消费记录',
                path:'orderRecode',
                component: resolve => require(["@/views/integral/orderRecode.vue"], resolve),
            },
            {
                name:'固定码列表',
                path:'fix_code_list',
                component: resolve => require(["@/views/integral/fixedCode.vue"], resolve),
            },
            {
                name:'流程列表',
                path:'flow_check_list',
                component: resolve => require(["@/views/integral/flowList.vue"], resolve),
            },
            {
                path: 'exprese_detail',
                name:'消费详情',
                component: resolve => require(["@/views/integral/expenseDetail.vue"], resolve),
            },
            
        ]
    },
    //预排期计划
    {
        path :'/home/ready_plan',
        component: Home,
        children:[
            {
                name:'添加预排期',
                path:'add_ready_plan',
                component: resolve => require(["@/views/ready_plan/addReadyPlan.vue"], resolve),
            },
            {
                name:'计划列表（BOM）',
                path:'get_page_bom_list',
                component: resolve => require(["@/views/ready_plan/bomList.vue"], resolve),
            },
            {
                name:'计划列表（部件）',
                path:'get_parts_page_list',
                component: resolve => require(["@/views/ready_plan/partsList.vue"], resolve),
            },
            {
                name:'日志',
                path:'get_log_list',
                component: resolve => require(["@/views/ready_plan/logList.vue"], resolve),
            },
        ]
    },
    //工作相关
    {
        path :'/home/work',
        component: Home,
        children:[
            {
                name:'产品查询',
                path:'common_goods_list',
                component: resolve => require(["@/views/work/productQuery.vue"], resolve),
            },
            {
                name:'订单查询',
                path:'order_list',
                component: resolve => require(["@/views/work/orderList.vue"], resolve),
            },
            {
                name:'排产列表',
                path:'demand_list',
                component: resolve => require(["@/views/work/demandList.vue"], resolve),
            },
            {
                name:'风电列表',
                path:'wind_list',
                component: resolve => require(["@/views/work/windList.vue"], resolve),
            },
            {
                name:'风电列表-历史数据',
                path:'history',
                component: resolve => require(["@/views/work/historyList.vue"], resolve),
            },
            {
                name:'风电联系人',
                path:'link_man',
                component: resolve => require(["@/views/work/linkMan.vue"], resolve),
            },
            {
                name:'产品预测',
                path:'link_man',
                component: resolve => require(["@/views/work/linkMan.vue"], resolve),
            },
        ]
    },
    //固定资产
    //工作相关
    {
        path :'/home/zi_chan',
        component: Home,
        children:[
            {
                name:'固定资产',
                path:'zc_list',
                component: resolve => require(["@/views/work/zcList.vue"], resolve),
            },
        ]
    },
    //工厂请假审批
    {
        path :'/home/sc_leave_manage',
        component: Home,
        children:[
            {
                name:'工厂请假审批',
                path:'manage_list',
                component: resolve => require(["@/views/work/dataList.vue"], resolve),
               
            },
        ]
    },
    //产品预测
    {
        path :'/home/product_predict',
        component: Home,
        children:[
            {
                name:'产品预测',
                path:'company_list',
                component: resolve => require(["@/views/work/companyList.vue"], resolve),
            },
            {
                name:'产品全部预测',
                path:'week_total_predict',
                component: resolve => require(["@/views/work/weekTotalPredice.vue"], resolve),
            },
            {
                name:'产品预测-预测-old',
                path:'forecast',
                component: resolve => require(["@/views/work/forecastList.vue"], resolve),
            },
            {
                name:'产品预测-预测-new',
                path:'forecast1',
                component: resolve => require(["@/views/work/forecastList1.vue"], resolve),
            },
            {
                name:'产品预测值列表',
                path:'predicted_value',
                component: resolve => require(["@/views/work/predictedValueList.vue"], resolve),
            },
            {
                name:'产品预测-查看',
                path:'look',
                component: resolve => require(["@/views/work/lookList.vue"], resolve),
            },
            {
                name:'产品预测-查看',
                path:'look1',
                component: resolve => require(["@/views/work/lookList1.vue"], resolve),
            },
            {
                name:'产品预测-日志',
                path:'log',
                component: resolve => require(["@/views/work/logList.vue"], resolve),
            },
            {
                name:'产品预测审核',
                path:'manage_list',
                component: resolve => require(["@/views/work/manageList.vue"], resolve),
            },
        ]
    },
    //产品信息查询
    {
        path :'/home/dg_goods',
        component: Home,
        children:[
            {
                name:'产品信息查询',
                path:'dg_goods_list',
                component: resolve => require(["@/views/work/productInfo.vue"], resolve),
            },
        ]
    },
    //员工管理
    {
        path :'/home/sc_user',
        component: Home,
        children:[
            {
                name:'春节休假推送',
                path:'user_holiday_ts_list',
                component: resolve => require(["@/views/staff/userHolidayList.vue"], resolve),
            },
            {
                name:'休假统计',
                path:'user_holiday_list',
                component: resolve => require(["@/views/staff/HolidayResultList.vue"], resolve),
            },
            {
                name:'员工列表',
                path:'user_page',
                component: resolve => require(["@/views/staff/userList.vue"], resolve),
            },
            {
                name:'员工列表-添加',
                path:'user_add',
                component: resolve => require(["@/views/staff/addUserInfo.vue"], resolve),
            },
            {
                name:'员工列表-编辑/详情',
                path:'user_edit',
                component: resolve => require(["@/views/staff/editUserInfo.vue"], resolve),
            },
            {
                name:'员工列表-编辑-月度报告详情',
                path:'user_report_detail',
                component: resolve => require(["@/views/staff/monthResult.vue"], resolve),
            },
            {
                name:'员工列表-编辑-评价表',
                path:'user_report_eval',
                component: resolve => require(["@/views/staff/submitEval.vue"], resolve),
            },
            {
                name:'员工列表-编辑-评价表',
                path:'user_report_inter',
                component: resolve => require(["@/views/staff/submitInter.vue"], resolve),
            },
            {
                name:'员工审核列表',
                path:'user_check_list',
                component: resolve => require(["@/views/staff/userCheckList.vue"], resolve),
            },
            {
                name:'员工审核列表-详情',
                path:'user_check_detail',
                component: resolve => require(["@/views/staff/userCheckDetail.vue"], resolve),
            },
            {
                name:'员工历史记录',
                path:'history_list',
                component: resolve => require(["@/views/staff/historyList.vue"], resolve),
            },
            {
                name:'员工历史记录-详情',
                path:'history_detail',
                component: resolve => require(["@/views/staff/historyDetail.vue"], resolve),
            },
            {
                name:'基本配置列表',
                path:'config_list',
                component: resolve => require(["@/views/staff/configList.vue"], resolve),
            },
            {
                name:'配置协议列表',
                path:'agree_type_list',
                component: resolve => require(["@/views/staff/agreeTypeList.vue"], resolve),
            },
            {
                name:'员工报表',
                path:'user_report',
                component: resolve => require(["@/views/staff/userReport.vue"], resolve),
            },
            {
                name:'实时人数报表',
                path:'real_time_report',
                component: resolve => require(["@/views/staff/realTimeReport.vue"], resolve),
            },
        ]
    },
     //招聘管理
    {
        path :'/home/zhao_pin',
        component: Home,
        children:[
            {
                name:'官网职位列表',
                path:'gw_position_list',
                component: resolve => require(["@/views/gw/positionList.vue"], resolve),
            },
            {
                name:'官网职位审核页面',
                path:'gw_position_manage',
                component: resolve => require(["@/views/gw/manage.vue"], resolve),
            },
            {
                name:'简历列表',
                path:'resume_list',
                component: resolve => require(["@/views/gw/resumeList.vue"], resolve),
                meta:{
                    keepAlive:true
                }
                
            },
            {
                name:'简历列表--添加简历',
                path:'add_resume_list',
                component: resolve => require(["@/views/gw/addResumeList.vue"], resolve),
            },
            
            {
                name:'登记表',
                path:'user_register_list',
                component: resolve => require(["@/views/job/userRegList.vue"], resolve),
            },
            {
                name:'邮件短信模板管理',
                path:'message_temp_list',
                component: resolve => require(["@/views/job/msgTempList.vue"], resolve),
            },
            {
                name:'统计',
                path:'count_zp',
                component: resolve => require(["@/views/job/countZp.vue"], resolve),
            },
            {
                name:'职位管理',
                path:'position_total_list',
                component: resolve => require(["@/views/job/positionTotalList.vue"], resolve),
            },
            {
                name:'评估模板',
                path:'estimate_conf_list',
                component: resolve => require(["@/views/job/assessPlatList.vue"], resolve),
            },
            {
                name:'第三方职位',
                path:'position_region_list',
                component: resolve => require(["@/views/job/positionReg.vue"], resolve),
            },
            {
                name:'录用通知',
                path:'offer_list',
                component: resolve => require(["@/views/job/offerList.vue"], resolve),
            },
            
            {
                name:'到岗通知',
                path:'dao_gang_list',
                component: resolve => require(["@/views/job/daoGangList.vue"], resolve),
            },
            {
                name:'汇总统计',
                path:'resume_num_hz',
                component: resolve => require(["@/views/job/resumeNumList.vue"], resolve),
            },
            {
                name:'帮助文档',
                path:'help_doc',
                component: resolve => require(["@/views/job/help.vue"], resolve),
                meta:{
                    title:'招聘帮助文档'
                }
            },
        ]
     },
     //生产系统相关
    {
        path :'/home/emp',
        component: Home,
        children:[
            {
                name:'人员登录信息',
                path:'user_list',
                component: resolve => require(["@/views/emp/userList.vue"], resolve),
            },
            {
                name:'人员登录信息-详情/添加/编辑',
                path:'user_Edit',
                component: resolve => require(["@/views/emp/userEdit.vue"], resolve),
            },
            {
                name:'工人审核列表',
                path:'worker_check_list',
                component: resolve => require(["@/views/emp/workerCheckList.vue"], resolve),
            },
            {
                name:'工人审核列表-详情/审核',
                path:'worker_check_edit',
                component: resolve => require(["@/views/emp/workerCheckDetail.vue"], resolve),
            },
        ]
    },
    //单页面
    {
        name:'圣诞节首页',
        path:'/home/christmas',
        component: resolve => require(["@/views/christmas/christmasIndex.vue"], resolve),
    }, 
    {
        name:'圣诞节首页',
        path:'/home/login/activityIndex',
        component: resolve => require(["@/views/christmas/index.vue"], resolve),
    },
    {
        name:'抽奖',
        path:'/home/login/activityDraw',
        component: resolve => require(["@/views/christmas/luckDraw.vue"], resolve),
    },
    {
        name:'报价-登录',
        path:'/price-login',
        component: resolve => require(["@/views/price/priceLogin.vue"], resolve),
    },
    {
        name:'报价-首页',
        path:'/price-index',
        component: resolve => require(["@/views/price/priceIndex.vue"], resolve),
    },
    {
        name:'报价-首页',
        path:'/index.php/Home/PriceNew/index',
        component: resolve => require(["@/views/price/priceIndex.vue"], resolve),
        meta:{
            // keepAlive:true
        }
    },
    {
        name:'报价-生成报价单',
        path:'/total-price',
        component: resolve => require(["@/views/price/totalPrice.vue"], resolve),
    },
    {
        name:'科拉斯考车申请单',
        path:'/driver_audit',
        component: resolve => require(["@/views/car/driverAudit.vue"], resolve),
    },
    {
        name:'成功页面',
        path:'/success_msg',
        component: resolve => require(["@/views/Home/successMessage.vue"], resolve),
    },
    {
        name:'春节休假推送页面',
        path:'/holiday_push_detail',
        component: resolve => require(["@/views/staff/holidayPushDetail.vue"], resolve),
    },
    {
        name:'入职员工信息申请表',
        path:'/user_join_detail',
        component: resolve => require(["@/views/staff/userJoinDetail.vue"], resolve),
    },
   
    {
        name:'离职详情',
        path:'/user_leave_detail',
        component: resolve => require(["@/views/staff/userLeaveDetail.vue"], resolve),
    },
    {
        name:'辞职详情',
        path:'/user_resign_detail',
        component: resolve => require(["@/views/staff/userResignDetail.vue"], resolve),
    },
    {
        name:'解雇详情',
        path:'/user_layoff_detail',
        component: resolve => require(["@/views/staff/userLayoffnDetail.vue"], resolve),
    },
    {
        name:'评估模板-预览',
        path:'/assess_table',
        component: resolve => require(["@/views/job/assessTable.vue"], resolve),
    },
    {
        name:'录用通知-预览',
        path:'/preview_list',
        component: resolve => require(["@/views/job/previewList.vue"], resolve),
       
    },
    {
        name:'生产管理登录',
        path:'/emp_login',
        component: resolve => require(["@/views/login/empLogin.vue"], resolve),
        meta:{
            is_emp:'1'
        }
    },
    {
        name:'登记表-员工登记表详情',
        path:'/user_detail',
        component: resolve => require(["@/views/job/userDetail.vue"], resolve),
    },
    {
        name:'模板管理-预览',
        path:'/message_temp_detail',
        component: resolve => require(["@/views/job/msgTempDetail.vue"], resolve),
    },
    {
        path: '/expense',
        name:'车辆使用费用确认单',
        component: resolve => require(["@/views/car/carExpense.vue"], resolve),
    },
    {
        path: '/carInfo',
        name:'车辆基本信息',
        component: resolve => require(["@/views/car_admin/carInfo.vue"], resolve),
    },
    {
        name:'移动端物品领取',
        path:'/product_buy',
        component: resolve => require(["@/views/office/productBuy.vue"], resolve),
    },
    {
        path: '/login',
        name:'登录',
        component: resolve => require(["@/views/login/index.vue"], resolve),
    },
    {
        path: '/goodsList',
        name:'物品列表',
        component: resolve => require(["@/views/office/goodsList.vue"], resolve),
    },
    {
        path: '/table',
        name:'粘贴表格',
        component: resolve => require(["@/views/tableList.vue"], resolve),
    },
    {
        path: '/user-register',
        name:'招聘员工登记表',
        component: resolve => require(["@/views/gw/userRegister.vue"], resolve),
        meta:{
            title:'员工登记表-科拉斯公司'
        }
    },
    {
        path: '/user-register-file',
        name:'招聘员工文件',
        component: resolve => require(["@/views/gw/userRegisterFile.vue"], resolve),
        meta:{
            title:'员工登记表-科拉斯公司'
        }
    },
    {
        path: '/staff_push',
        name:'员工信息推送页面',
        component: resolve => require(["@/views/staff/staffPush.vue"], resolve),
    },
    {
        path: '/worker_push',
        name:'工人信息推送页面',
        component: resolve => require(["@/views/staff/workerPush11.vue"], resolve),
    },
    {
        path: '/resume_detail',
        name:'招聘简历',
        component: resolve => require(["@/views/gw/resumeDetail.vue"], resolve),
    },
    {
        path: '/resume_detail/wy_report',
        name:'招聘简历-前程无忧',
        component: resolve => require(["@/views/gw/wyReport.vue"], resolve),
    },
    {
        path: '/resume_detail/zl_report',
        name:'招聘简历-智联招聘',
        component: resolve => require(["@/views/gw/zlReport.vue"], resolve),
    },
    {
        path: '/audit_file',
        name:'新员工入职前背景调查表',
        component: resolve => require(["@/views/gw/auditFile.vue"], resolve),
    },
    {
        path: '/interview_table',
        name:'面试评估表',
        component: resolve => require(["@/views/gw/interviewTable.vue"], resolve),
    },
    {
        path: '/hr_add_report',
        name:'人事背调-添加报告',
        component: resolve => require(["@/views/gw/hrAddReport.vue"], resolve),
    },
    {
        path: '/hr_look_report',
        name:'人事背调-查看报告',
        component: resolve => require(["@/views/gw/hrLookReport.vue"], resolve),
    },
    {
        path: '/birthday',
        name:'贺卡',
        component: resolve => require(["@/views/gw/birthday.vue"], resolve),
    },
    {
        path: '/entry',
        name:'入职单页面',
        component: resolve => require(["@/views/gw/entry.vue"], resolve),
    },
    {
        path: '/report-upload',
        name:'入职前背景调查报告确认',
        component: resolve => require(["@/views/gw/reportUpload.vue"], resolve),
    },
    {
        path: '/offer-sign',
        name:'录用通知签名',
        component: resolve => require(["@/views/gw/offerSign.vue"], resolve),
    },
    {
        path: '/download-list',
        name:'简历下载',
        component: resolve => require(["@/views/gw/downloadList.vue"], resolve),
    },
    {
        path: '/weiZhangDetail',
        name:'使用人违章详情',
        component: resolve => require(["@/views/car_admin/wzDetail.vue"], resolve),
    },
    {
        path: '/accident-detail',
        name:'事故统计-记录单',
        component: resolve => require(["@/views/car_admin/accidentDetail.vue"], resolve),
    },
]
function extractRoutes(routes, basePath = '') {
    const result = []

    for (const route of routes) {
      const fullPath =
      basePath + (route.path.startsWith('/') ? route.path : '/' + route.path)
      result.push(fullPath)

      if (route.children) {
      const childRoutes = extractRoutes(route.children, fullPath)
      result.push(...childRoutes)
      }
    }

return result
}
export const extractedRoutes = extractRoutes(routes)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router































